<template>
  <Loading v-if="isGetTaskHistoryPending" style="margin-top: 50px" />
  <div v-else-if="taskHistory.length" class="task-history">
    <div>
      <transition-group class="messages-list" tag="div" name="history-list">
        <!-- <transition-group v-if="milestone.expanded" name="task-list" class="task-list" tag="ul"> -->
        <div v-for="message in taskHistory" :key="message.id">
          <div class="message-wrapper">
            <widget v-bind="message" @click="click(message)" @showForm="showForm(message)" />
          </div>
          <div v-if="expandedDetais.includes(message.id)" class="details-wrapper">
            <div class="header">Task:</div>
            <task-values :data="{ task: message.metadata.task, invention }" />
          </div>
          <div v-if="expandedForms.includes(message.id)" class="form-wrapper">
            <div class="header">Form:</div>
            <form-values :data="message.metadata.form.properties" :invention="invention" />
          </div>
        </div>
      </transition-group>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';

import Widget from '../Widget.vue';
import TaskValues from './TaskValues.vue';
import FormValues from './FormValues.vue';
import Loading from '@/components/common/Loading';

export default {
  components: {
    Widget,
    Loading,
    TaskValues,
    FormValues
  },
  props: {
    taskId: {
      type: String,
      required: true
    },
    invention: {
      type: Object,
      required: true,
      default: null
    }
  },
  data() {
    return {
      expandedDetais: [],
      expandedForms: []
    };
  },
  computed: {
    ...mapState({
      isGetTaskHistoryPending: s => s.tasks.isGetTaskHistoryPending,
      taskHistory: s => s.tasks.taskHistory,
      details: s => s.reports.myInventions.details
    })
  },
  async created() {
    await this.$store.dispatch('tasks/getTaskHistory', { taskId: this.taskId });
  },
  methods: {
    click(item) {
      const index = this.expandedDetais.findIndex(i => i === item.id);
      if (index === -1) {
        this.expandedDetais.push(item.id);
      } else {
        this.expandedDetais.splice(index, 1);
      }
    },
    showForm(item) {
      const index = this.expandedForms.findIndex(i => i === item.id);
      if (index === -1) {
        this.expandedForms.push(item.id);
      } else {
        this.expandedForms.splice(index, 1);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.task-history {
  padding: 5px;
  background: var(--theme-background);
  border-radius: 2px;
}
.no-history-message {
  font-style: italic;
  margin: 3px 0;
}

.history-list-move,
.history-list-enter-active,
.history-leave-active {
  transition: all 0.5s ease;
}

.header {
  font-weight: 500;
  font-size: 0.8rem;
  letter-spacing: 0.025em;
  margin-bottom: 5px;
}
.messages-list {
  display: grid;
  grid-template-rows: max-content;
  background: var(--theme-background);
  grid-gap: 5px;
  .details-wrapper,
  .form-wrapper {
    padding: 10px 20px;
  }
}
</style>
